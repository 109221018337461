import Axios from 'axios';
import {PROJECT_LIST_REQUEST, PROJECT_LIST_SUCCESS, PROJECT_LIST_FAIL, PROJECT_DETAILS_REQUEST, PROJECT_DETAILS_SUCCESS, PROJECT_DETAILS_FAIL, PROJECT_CREATE_REQUEST, PROJECT_CREATE_SUCCESS, PROJECT_CREATE_FAIL, PROJECT_UPDATE_REQUEST, PROJECT_UPDATE_SUCCESS, PROJECT_UPDATE_FAIL, PROJECT_DELETE_REQUEST, PROJECT_DELETE_SUCCESS, PROJECT_DELETE_FAIL} from '../constants/project';

export const listProjects = () => async (dispatch) => {
    dispatch({ type: PROJECT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(`/project`);
        dispatch({ type: PROJECT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: PROJECT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const detailProject = (projectId) => async (dispatch) => {
    dispatch({ type: PROJECT_DETAILS_REQUEST, payload: projectId });
    try {
        const { data } = await Axios.get(`/project/${projectId}`);
        dispatch({ type: PROJECT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: PROJECT_DETAILS_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const createProject = (project) => async (dispatch, getState) => {
    dispatch({ type: PROJECT_CREATE_REQUEST, payload: project });
    const { userLogin: { user } } = getState();
    try {
        const { data } = await Axios.post(`/project`, project, {
            headers: { Authorization: `Bearer ${user?.token}` }
        });
        dispatch({ type: PROJECT_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: PROJECT_CREATE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const updateProject = (project) => async (dispatch, getState) => {
    dispatch({ type: PROJECT_UPDATE_REQUEST, payload: project });
    const { userLogin: { user } } = getState();
    try {
        const { data } = await Axios.put(`/project/${project._id}`, project, {
            headers: { Authorization: `Bearer ${user?.token}` }
        });
        dispatch({ type: PROJECT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: PROJECT_UPDATE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const deleteProject = (projectId) => async (dispatch, getState) => {
    dispatch({ type: PROJECT_DELETE_REQUEST, payload: projectId });
    const { userLogin: { user } } = getState();
    try {
        const { data } = await Axios.delete(`/project/${projectId}`, {
            headers: { Authorization: `Bearer ${user?.token}` }
        });
        dispatch({ type: PROJECT_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: PROJECT_DELETE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};