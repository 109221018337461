import { configureStore } from '@reduxjs/toolkit'

import {userRegister, userLogin, userProfile} from './reducers/user.js';
import {projectList, projectDetail, projectCreate, projectUpdate, projectDelete} from './reducers/project.js';

const preloadedState = {
    userLogin: {
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    },
};

const reducer = {
    userRegister: userRegister,
    userLogin: userLogin,
    userProfile: userProfile,
    projectList: projectList,
    projectDetail: projectDetail,
    projectCreate: projectCreate,
    projectUpdate: projectUpdate,
    projectDelete: projectDelete,
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
});
  
export default store;