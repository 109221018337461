import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { detailProject, createProject, updateProject } from '../actions/project';
import { PROJECT_CREATE_RESET, PROJECT_UPDATE_RESET } from '../constants/project';

import { Grid, Alert, LinearProgress, FormControlLabel, Checkbox, TextField, InputLabel, Box } from '@mui/material';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers'

import {ReactComponent as Arrow} from '../assets/svg/arrow-right.svg'

export default function ProjectForm() {
  const params = useParams();
  const navigate = useNavigate();
  const { id: projectId } = params;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [role, setRole] = useState('');
  const [technology, setTechnology] = useState([]);
  const [images, setImages] = useState([]);
  const [url, setURL] = useState('');
  const [repository, setRepository] = useState('');
  const [date, setDate] = useState(new Date());
  const [isHidden, setIsHidden] = useState(true);
  const projectDetail = useSelector((state) => state.projectDetail);
  const { loading, error, project } = projectDetail;
  const projectCreate = useSelector((state) => state.projectCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    project: createdProject,
  } = projectCreate;
  const projectUpdate = useSelector((state) => state.projectUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    project: updatedProject,
  } = projectUpdate;
  const dispatch = useDispatch();
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PROJECT_CREATE_RESET });
      navigate(`${createdProject._id}`)
    } else if (successUpdate){
      dispatch({ type: PROJECT_UPDATE_RESET });
      dispatch(detailProject(updatedProject._id));
    } else if (!project && projectId) {
      dispatch({ type: PROJECT_UPDATE_RESET });
      dispatch(detailProject(projectId));
    } else if (project) {
      setName(project.name);
      setDescription(project.description);
      setRole(project.role);
      setTechnology(project.technology);
      setImages(project.images);
      setURL(project.url);
      setRepository(project.repository);
      setDate(project.date);
      setIsHidden(project.isHidden);
    }
  }, [project, dispatch, projectId, successCreate, successUpdate, createdProject, updatedProject, navigate]);
  const updateHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProject({
            _id: projectId,
            name,
            description,
            role,
            technology,
            images,
            url,
            repository,
            date,
            isHidden
      })
    );
  };
  const createHandler = () => {
    dispatch(
      createProject({
        name,
        description,
        role,
        technology,
        images,
        url,
        repository,
        date,
        isHidden
      })
    );
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div>
        {loadingCreate && <LinearProgress color="inherit"/>}
        {errorCreate && <Alert severity="error">{errorCreate}</Alert>}
        {loadingUpdate && <LinearProgress color="inherit"/>}
        {errorUpdate && <Alert severity="error">{errorUpdate}</Alert>}
        {loading ? (
          <LinearProgress color="inherit"/>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Grid container direction="column" rowSpacing={4} p={4}>
            <h1 style={{ marginBottom: 0, marginTop: "2rem" }}>Project Form™</h1>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-name">Name.</InputLabel>
              <TextField
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="project-name"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-description">Description.</InputLabel>
              <TextField
                variant="outlined"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="project-description"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-role">Role.</InputLabel>
              <TextField
                variant="outlined"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                id="project-role"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-technology">Technology.</InputLabel>
              <TextField
                variant="outlined"
                value={technology}
                onChange={(e) => setTechnology(e.target.value.split(','))}
                id="project-technology"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-images">Images.</InputLabel>
              <TextField
                variant="outlined"
                value={images}
                onChange={(e) => setImages(e.target.value.split(','))}
                id="project-images"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-url">URL.</InputLabel>
              <TextField
                variant="outlined"
                value={url}
                onChange={(e) => setURL(e.target.value)}
                id="project-url"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-repository">Repository.</InputLabel>
              <TextField
                variant="outlined"
                value={repository}
                onChange={(e) => setRepository(e.target.value)}
                id="project-repository"
                className="project-text-field"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="project-label" htmlFor="project-date">Date.</InputLabel>
              <DatePicker 
                inputVariant="outlined"
                value={date} 
                onChange={(e) => setDate(e)} 
                id="project-date"
                className="project-text-field"
                />
            </Grid>
            <Grid item xs={12} ml={1.1} mb={3}>
              <FormControlLabel control=
                {<Checkbox 
                  checked={isHidden} 
                  onChange={(e) => setIsHidden(e.target.checked)}
                  style ={{
                    color: "#000",
                  }}
                />} 
              label="Click the checkbox to hide this project."/>
            </Grid>
            { project ? 
            <Grid onClick={updateHandler} container direction="row" justifyContent="space-between" alignItems="center" sx={{ cursor: "pointer", backgroundColor: "black", padding:"10px", width: "60vw" }}> 
              <b style={{color: "white", mr: 4}}>Save your changes. &nbsp;</b>
              <Box display="flex" alignItems="center">
                <Arrow fill="white" width="30px" height="30px"/>
              </Box>
            </Grid> : 
            <Grid onClick={createHandler} container direction="row" justifyContent="space-between" alignItems="center" sx={{ cursor: "pointer", backgroundColor: "black", padding:"10px", width: "60vw" }}> 
              <b style={{color: "white", mr: 4}}>Create a new project. &nbsp;</b>
              <Box display="flex" alignItems="center">
                <Arrow fill="white" width="30px" height="30px"/>
              </Box>
            </Grid>
            }   
          </Grid>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}