import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from '../actions/user';
import { gsap } from 'gsap';

import { Grid,  LinearProgress, Input, InputAdornment, IconButton, FormControl } from '@mui/material';
import { ReactComponent as LoginButton } from '../assets/svg/login-button.svg'
import { ReactComponent as QuotationMark } from '../assets/svg/quotation-mark.svg'
import { quotes } from '../assets/quotes'

export default function Login() {
  const [email] = useState('mathena828@gmail.com');
  const [password, setPassword] = useState('');
  const [index, setIndex] = useState(0);
  const quoteRef = useRef(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';
  const generate = () => {
    setIndex(Math.floor(Math.random()*quotes.length))
  }
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  useEffect(() => {
    if (user) {
      navigate(redirect);
    }
  }, [navigate, redirect, user]);
  useLayoutEffect(() => {
    gsap.fromTo(quoteRef.current,
      {
          yPercent: 100,
      },
      {
          duration: 1,
          yPercent: 0,
          ease: "power4.out",
      })
  })
  return (
    <div>
      {loading ? (
        <LinearProgress color="inherit"/>
      ) : error ? (
        <div></div>
      ) : ( 
        <Grid container sx={{ p: 4, fontSize: "18px" }}>
          <Grid item xs={12}>
            <h2>TOP SECRET</h2>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
            <Input 
              id="password-input" 
              placeholder="Enter the password"
              onChange={(e) => setPassword(e.target.value)}
              sx={{ fontSize:"10vh", borderBottom: "2.5px solid black", outline: "none" }}
              disableUnderline={true}
              type="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="admin login"
                    onClick={submitHandler}
                    className="admin-login"
                  >
                    <LoginButton width="15vh" height="15vh" sx={{ position:"relative" }}/>
                  </IconButton>
                </InputAdornment>
              }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} my={4}>
            <span style={{ fontSize: "18px" }}><b>MESSAGE — </b>from the creator</span>
            <p>Thank you for visiting my website and well done for finding the hidden admin login page of my portfolio. I hope you had as much fun browsing the website as I did making it. To show my appreciation, please enjoy a quote from one of my favorite books.</p>
              { quotes[index] ?
                <div style={{ overflow:"hidden" }}>
                  <div ref={quoteRef}>
                      <div><sup><QuotationMark/></sup><span style={{ marginLeft: "10px" }}>{quotes[index].quote}</span></div>
                      <p>— {quotes[index].source}</p>
                  </div> 
                </div>
                :
                <div>
                  <div><sup><QuotationMark/></sup><span style={{ marginLeft: "10px" }}>For a moment, nothing happened. Then, after a second or so, nothing continued to happen.</span></div>
                  <p>— Douglas Adams, The Hitchhiker's Guide to the Galaxy (1979)</p>
                </div>
              }
            <b onClick={generate} style={{ cursor: "pointer" }}>Click here to generate a new quote.</b>
          </Grid> 
        </Grid>
      )}
    </div>
  );
}