import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProjects, deleteProject } from '../actions/project';
import { PROJECT_DELETE_RESET } from '../constants/project';

import { Grid, Alert, LinearProgress, Box, Button } from '@mui/material';
import { DataGrid } from '@material-ui/data-grid'
import { Link } from 'react-router-dom';

import {ReactComponent as Arrow} from '../assets/svg/arrow-right.svg'

export default function ProjectForm() {
  const projectList = useSelector((state) => state.projectList);
  const { loading, error, projects } = projectList;
  const projectDelete = useSelector((state) => state.projectDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = projectDelete;
  const dispatch = useDispatch();
  useEffect(() => {
    if (successDelete) {
      dispatch({ type: PROJECT_DELETE_RESET });
    }
    dispatch(listProjects());
  }, [dispatch, successDelete]);
  const deleteHandler = (project) => {
    dispatch(deleteProject(project.id));
  };
  return (
      <div>
        {loadingDelete && <LinearProgress color="inherit"/>}
        {errorDelete && <Alert severity="error">{errorDelete}</Alert>}
        {loading ? (
          <LinearProgress color="inherit"/>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Grid container p={4}>
            <Grid xs={12} item container direction="row" justifyContent="space-between" alignItems="center">
              <h1>Project List<sup> ©</sup></h1>
              <Link to={`/projects/form`}> 
                <Grid xs={12} item container direction="row" justifyContent="space-between" alignItems="center" sx={{ cursor: "pointer", backgroundColor: "black", padding:"10px" }}>
                  <b style={{color: "white", mr: 4}}>Create a new project. &nbsp;</b>
                  <Box display="flex" alignItems="center">
                    <Arrow fill="white" width="30px" height="30px"/>
                  </Box>
                </Grid>
              </Link>
            </Grid>
            <DataGrid
                columns={[
                    { field: 'name', flex: 1, headerName: 'Name', renderCell: (params) => {
                      return (
                          <Link to={`/projects/${params.id}`}>
                              {params.row.name}
                          </Link>
                    )}}, 
                    { field: 'isHidden', flex: 1, headerName: 'Hidden?' },
                    { field: 'delete', flex: 1, headerName: 'Delete', renderCell: (params) => {
                        return (
                            <Button onClick={() => deleteHandler(params)}>
                                Delete
                            </Button>
                    )}},
                    { field: 'edit', flex: 1, headerName: 'Edit', renderCell: (params) => {
                      return (
                          <Link to={`/projects/form/${params.id}`}>
                              Edit
                          </Link>
                    )}}
                ]}
                rows={projects}
                getRowId={row => row._id}
                pageSize={5}
                rowsPerPageOptions={[5]}
                autoHeight {...projects} 
                />
          </Grid>
        )}
      </div>
  );
}