export const quotes = [
    {
        quote: "Remember, always, that everything you know, and everything everyone knows, is only a model. Get your model out there where it can be viewed. Invite others to challenge your assumptions and add their own.",
        source: "Donella Meadows, Thinking in Systems: A Primer (2008)"
    }, 
    {
        quote: "Happiness does not really depend on objective conditions of either wealth, health or even community. Rather, it depends on the correlation between objective conditions and subjective expectations.",
        source: "Yuval Noah Harari, Sapiens: A Brief History of Humankind (2011)"
    },
    {
        quote: "If there really is a complete unified theory that governs everything, it presumably also determines your actions. But it does so in a way that is impossible to calculate for an organism that is as complicated as a human being. The reason we say that humans have free will is because we can't predict what they will do.",
        source: "Stephen Hawking, A Brief History of Time (1988)"
    }, 
    {
        quote: "If you want to be a good intuitive Bayesian—if you want to naturally make good predictions, without having to think about what kind of prediction rule is appropriate—you need to protect your priors.",
        source: "Brian Christian, Algorithms to Live By: The Computer Science of Human Decisions (2016)"
    },
    {
        quote: "The perception of an isomorphism between two known structures is a significant advance in knowledge-and I claim that it is such perceptions of isomorphism which create meanings in the minds of people.",
        source: "Douglas R. Hofstadter, Gödel, Escher, Bach: An Eternal Golden Braid (1979)"
    },
    {
        quote: "We are made of the same stardust of which all things are made, and when we are immersed in suffering or when we are experiencing intense joy we are being nothing other than what we can’t help but be: a part of our world.",
        source: "Carlo Rovelli, Seven Brief Lessons on Physics (2014)"
    },
    {
        quote: "We are a way for the cosmos to know itself.",
        source: "Carl Sagan, Cosmos (1980)"
    },
    {
        quote: "Any altruistic system is inherently unstable, because it is open to abuse by selfish individuals, ready to exploit it.",
        source: "Richard Dawkins, The Selfish Gene (1976)"
    },
    {
        quote: "Money’s greatest intrinsic value—and this can’t be overstated—is its ability to give you control over your time.",
        source: "Morgan Housel, The Psychology of Money (2020)"
    },
    {
        quote: "Knowing what to measure and how to measure it makes a complicated world much less so.",
        source: "Steven D. Levitt, Freakonomics: A Rogue Economist Explores the Hidden Side of Everything (2005)"
    },
    {
        quote: "Whereas the complexity of an object measures how complicated it is to describe, its information content measures the extent to which it describes the rest of the world. In other words, information is a measure of how much meaning complexity has.",
        source: "Max Tegmark, Our Mathematical Universe: My Quest for the Ultimate Nature of Reality (2012)"
    }
];