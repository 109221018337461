import React, { useState, useEffect, Suspense }  from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import PrivateRoute from './components/PrivateRoute';
import Login from './views/Login';
import ProjectForm from './views/ProjectForm';
import Projects from './views/Projects';
import Project from './views/Project';
import Navbar from './components/Navbar';
import NotFound from './views/NotFound';
import FullScreenMenu from './components/FullScreenMenu';
import Loading from './views/Loading';
// import Home from './views/Home'

const Home = React.lazy(() => lazyRetry(() => new Promise(resolve => {
  setTimeout(() => resolve(import("./views/Home")), 3000);
})));

const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );
      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); 
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) {
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); 
              return window.location.reload(); 
          }
          reject(error); 
      });
  });
};


const theme = createTheme({
  typography: {
    fontFamily: [   
      "Helvetica Neue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(",")
  },
});

function App() {
  const [open, setOpen] = useState(false);
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <ThemeProvider theme={theme}>
        <div>
          <header>
            <Navbar open={open} setOpen={setOpen}></Navbar>
            <FullScreenMenu open={open} setOpen={setOpen}></FullScreenMenu>
          </header>
          <main>
              <Suspense fallback={<Loading/>}>
              <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route
                  path="/projects/form/:id"
                  element={<PrivateRoute>
                    <ProjectForm />
                  </PrivateRoute>}
                ></Route>
                <Route
                  path="/projects/form"
                  element={<PrivateRoute>
                    <ProjectForm />
                  </PrivateRoute>}
                ></Route>
                <Route
                  path="/projects/:id"
                  element={
                    <Project />}
                ></Route>
                <Route
                  path="/projects"
                  element={<PrivateRoute>
                    <Projects />
                  </PrivateRoute>}
                  exact
                ></Route>
                <Route path="/" element={<Home />}></Route>
                {/* <Route path="/loading" element={<Loading />}/> */}
                <Route path="*" element={<NotFound />}/>
              </Routes>
              </Suspense>
          </main>
        </div>
    </ThemeProvider>
  );
}

export default App;