import Axios from 'axios';
import {USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_FAIL, USER_LOGOUT} from '../constants/user.js';

export const register = (name, email, password) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST, payload: {email, password} });
    try {
      const { data } = await Axios.post('/user/register', {
        name,
        email,
        password,
      });
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem('user', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
};
  
export const login = (email, password) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST, payload: {email, password} });
    try {
      const { data } = await Axios.post('/user/login', {email, password});
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem('user', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
};
  
export const logout = () => (dispatch) => {
    localStorage.removeItem('user');
    dispatch({ type: USER_LOGOUT });
    document.location.href = '/login';
};

export const profile = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_PROFILE_REQUEST, payload: userId });
    const { userLogin: { user } } = getState();
    try {
        const { data } = await Axios.get(`/user/${userId}`, {
            headers: { Authorization: `Bearer ${user?.token}` },
        });
        dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ 
            type: USER_PROFILE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message, 
        });
    }
};