import React, { useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { gsap } from 'gsap';

import { Home } from '@mui/icons-material';

export default function NotFound() {
    const errorRef = useRef(null);
    const underlineRef = useRef(null);
    useLayoutEffect(()=> {
        gsap.fromTo(errorRef.current,
        {
            yPercent: 100,
        },
        {
            duration: 1,
            yPercent: 0,
            ease: "power4.out",
        })   
    })
    return (
        <Grid container justifyContent="center" alignItems="center" px={3} sx={{ backgroundColor: "black", minHeight: "100vh", color:"white" }}>
            <Grid item container xs={12} p={3} sx={{ fontSize: "10vw"}}>
                <Grid item xs={12} my={1}>
                    Page Not Found
                    <span ref={underlineRef}></span>
                </Grid>
                <Grid item xs={12} my={1} sx={{ overflow: "hidden" }}>
                    <span className="not-found-pill" ref={errorRef}>404</span> Error
                </Grid>
                <Grid item xs={12} my={1}>
                    <Link to="/" style={{ color: "white", display: "flex", alignItem: "center" }}>Go <Home sx={{ fontSize: "12vw", pl: 2 }}/></Link>
                </Grid>
                
            </Grid>
        </Grid>
    );
}