import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { detailProject } from '../actions/project';
import { gsap } from 'gsap';

import { LinearProgress, Grid, Box} from '@mui/material';

export default function Project() {
    const params = useParams();
    const { id: projectId } = params;
    const location = useLocation()
    const { number } = location.state ?? 0;
    const dispatch = useDispatch();
    const coverRef = useRef(null);
    const imageRef = useRef(null);
    const projectDetail = useSelector((state) => state.projectDetail);
    const { loading, error, project } = projectDetail;
    useEffect(() => {
        dispatch(detailProject(projectId));
    }, [dispatch, projectId]);
    useLayoutEffect(() => {
        if (!loading) {
            gsap.timeline().from(coverRef.current,  {
                scaleX: 0, 
                transformOrigin: "left",
                duration: 1
            }).to(coverRef.current, {
                scaleX: 0, 
                transformOrigin: "right",
                duration: 1
            }, "uncover").from(imageRef.current, {
                opacity: 0,
                duration: 1,
            }, "uncover");
        }
    })
    return (
        <div>
            {loading || !project ? (
                <LinearProgress color="inherit"/>
            ) : error  ? (
                <div></div>
            ) : (
                <Box m={5} p={5} sx={{ backgroundColor:'#000000', color:'#ffffff', fontSize: "3vh" }}>
                    <Grid container columnSpacing={{md: 5}} rowSpacing={1}>
                        <Grid item container direction="row" md={12} lg={6}>
                            <Grid xs={12} item>
                                <div style={{ fontSize: "4vw", fontWeight: "bold", textTransform: "uppercase" }}>{number<10 ? "0" : ""}{number}. {project.name}™</div>
                            </Grid>
                            <Grid xs={12} item>
                                <h2>COPYRIGHT {parseInt(project.date)}</h2>
                                <p>{project.description}</p>
                                <a href={project.url ? project.url : project.repository} target="_blank" rel="noreferrer" style={{ color: "inherit", fontWeight: "bold", textDecoration:"underline" }}>Click here to see the project.</a>
                            </Grid>
                            <Grid xs={12} item my={3}> 
                                <b>Role</b>
                                <p>{project.role}</p>
                                <b >Technology</b>
                                <div>
                                    {project.technology.map((technology, index) => (
                                        <span key={index} className="tech-pill">{technology} </span>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item md={12} lg={6}> 
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", width:"100%", height:"100%",   }}>
                                <div ref={coverRef} className="cover" style={{ position: "absolute", width:"100%", height:"100%", backgroundColor:"white", zIndex: 1, }}></div>
                                <img ref={imageRef} alt="project" className="project-img" src={project.images[0]}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
}