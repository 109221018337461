import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { Grid } from '@mui/material';
import { loading_messages } from '../assets/loading_messages'

export default function Loading() {
    const [message, setMessage] = useState(null)
    const messageRef = useRef(null)
    useEffect(() => {
        const loading = document.getElementById('loading');
        const cursor = document.querySelector('.cursor');
        // const border = document.querySelector('.cursor-border');
        const editCursor = e => {
            const { clientX: x, clientY: y } = e;
            cursor.style.left = x + 'px';
            cursor.style.top = y + 'px';
            // border.style.left = x + 'px';
            // border.style.top = y + 'px';
        };
        loading.addEventListener('mousemove', editCursor);
        let links = Array.from(document.querySelectorAll('.social-link-loading'))
        links.forEach(link => {
            link.addEventListener('mouseover', () => {cursor.classList.add("grow")})
            link.addEventListener('mouseleave', () => {cursor.classList.remove("grow")})
        })
        setMessage(loading_messages[Math.floor(Math.random()*loading_messages.length)])
    }, []);
    useLayoutEffect(() => {
        gsap.fromTo(messageRef.current,
        {
            yPercent: 100,
        },
        {
            duration: 1,
            yPercent: 0,
            ease: "power4.out",
        })
    });
    return (
        <div id="loading" style={{ minHeight: "100vh", marginTop: "25px" }}>
            <Grid container p={5} direction="column" justifyContent="center"> 
                <Grid item xs={12}>
                    <span style={{ fontSize: "20px" }}><b>PORTFOLIO</b> © Mathena Angeles</span>
                </Grid>
                <Grid item xs={12} sx={{ fontSize: "6vh", marginTop: "1.5rem", marginBottom: "1rem", fontWeight: "bold", overflow: "hidden" }}>
                    <div ref={messageRef}>{message}</div>
                </Grid>
                <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} sm={2}>
                    <h3>Socials</h3>
                    <a href="https://www.instagram.com/mathenaangeles/" target="_blank" rel="noreferrer" className="social-link-loading"><p>Instagram</p></a>
                    <a href="https://www.linkedin.com/in/mathenaangeles" target="_blank" rel="noreferrer" className="social-link-loading"><p>LinkedIn</p></a>
                    <a href="https://github.com/mathenaangeles" target="_blank" rel="noreferrer" className="social-link-loading"><p>GitHub</p></a>  
                    <div className="cursor"></div>
                    <div className="cursor-border"></div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    <h3>Connect</h3>
                    <p onClick={() => window.location = 'mailto:mathena828@gmail.com'} className="social-link-loading">mathena828@gmail.com</p>
                    <a href="https://discord.com/users/Mathena#5483" target="_blank" rel="noreferrer" className="social-link-loading"><p>mathenaangeles</p></a>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}