import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';

import logo from '../assets/images/logo.png'

export default function Navbar({open, setOpen}) {
    return (
        <AppBar position="static" elevation={0} sx={{ backgroundColor:"white", p:1, borderBottom: 2, borderColor: "black" }}>
            <Toolbar sx={{ py: 0 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Link to="/">
                        <img src={logo} alt="Mathena Angeles" height="40"/>
                    </Link>
                </Box>
                 <IconButton
                    edge="end"
                    aria-label="menu"
                    disableRipple={true}
                    onClick={() => setOpen(!open)}
                >
                    <div className="burger">
                        <div className="burger-one"></div>
                        <div className="burger-two"></div>
                    </div>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

